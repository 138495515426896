.doctor-profile {
    margin-top: 8rem;
}
.doc{
    align-items: center;
}
hr {
    border: 1px solid #0ab1b9 !important;
    margin-top: 8px;
    margin-bottom: 8px;
}
.profile-section {
    padding: 20px;
    background-color: #f8f9fa;
    border-left: 5px solid #17a2b8;
}

.profile-img {
    max-width: 150px;
    height: 150px;
    border: 3px solid #17a2b8;
}

.about-sections,
.education-section,
.experience-section,
.memberships-section,
.specialization-section {
    padding: 20px;
    background-color: #f8f9fa;
    border-left: 5px solid #17a2b8;
}

.about-sections h3,
.education-section h3,
.experience-section h3,
.memberships-section h3,
.specialization-section h3 {
    background-color: #17a2b8;
    color: white;
    padding: 5px;
    border-radius: 3px;
}

ul {
    padding-left: 20px;
}

.info-sec {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.6;
}

.btn-danger {
    background-color: #dc3545;
}

.btn-success {
    background-color: #28a745;
}