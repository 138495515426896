.event-page {
    padding: 20px;
    margin-top: 4.5rem;
}

.event-title {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(90deg, #034ea1 0, #00b7ac 100%);
    /* margin-bottom: 20px; */
}

.sec-one,
.sec-three,
.intro {
    margin-left: 1rem;
}
.sec-two{
    margin-right: 1rem;
}

.sec-one img,
.sec-two img,
.sec-three img {
    width: 500px;
}

.sec-one{
    display: flex;
    margin-bottom: 2rem;
}
.sec-one ul {
    margin-left: 1rem;
    list-style: none;
}
.sec-one-shape img{
    height: 20px;
    width: 20px !important;
}
.sec-one-shape{
    display: flex;
    margin-left: 1rem;
}
.sec-one-shape p{
    margin-left: 10px;
    margin-top: -5px;
}
.sec-two{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 2rem;
}
.sec-two ul {
    /* margin-right: 1rem; */
    list-style: none;
}
.sec-two ul>li::before{
    content: "+";
    display: inline-block;
    margin-right: 10px;
}
.sec-one ul>li ,.sec-two ul>li{
    line-height: 2;
}
.sec-three{
    display: flex;
    margin-bottom: 2rem;
}
.sub-intro{
    margin-left: 1rem;
    font-weight: 800;
    font-size: x-large;
    color: #121111;
}
.event-gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin-top: 20px;
    padding: 0 1rem;
    margin-bottom: 20px;
  }
  
  .event-gallery-grid img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .event-gallery-grid img:hover {
    transform: scale(1.05);
  }
  

@media (max-width:768px){
    .sec-one img, .sec-two img, .sec-three img {
        width: 100%;
    }
    .sec-one ,.sec-two,.sec-three {
        flex-direction: column;
    }
    .sec-one-details , .sec-two-details, .sec-three-details{
        margin-top: 5px;
    }
    .sub-intro{
        text-align: center;
    }
    .sec-one,.sec-two, .sec-three, .intro {
        margin: 1rem;
        
    }
}