/* -------------------------------------- Ashwini Hospital Cuttack -------------------------------------------- */
/* Why coose us */
.chooseus-section {
    position: relative;
    padding: 15px 0px;
    background-color: #0E1136;
}

.chooseus-section .bg-layer {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    padding-right: 0;
}

.chooseus-section .bg-layer img {
    width: 100%;
    height: 100%;
}

.chooseus-section .light {
    text-align: left;
}

.chooseus-section .light h2 {
    color: #fff;
    font-size: 30px;
}

.chooseus-block:first-child .chooseus-block-one .inner-box,
.chooseus-block:nth-child(2) .chooseus-block-one .inner-box {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chooseus-block-one .inner-box {
    position: relative;
    display: block;
    padding-left: 110px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.chooseus-block-one .inner-box .icon-box {
    position: absolute;
    left: 0px;
    top: -20px;
    font-size: 80px;
    color: #fff;
    transition: all 500ms ease;
}

.chooseus-block-one .inner-box h3 {
    display: block;
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.chooseus-block-one .inner-box h3:hover {
    color: #f9313b;
}

.chooseus-block-one .inner-box p {
    color: #fff;
}

/* Neurology */
.doctors-box {
    margin-top: 3rem;
    margin-bottom: 15px;
    padding-top: 5rem;
}

.doc-text {
    padding: 5px;
    text-align: center;
}

.doc-text h3 {
    font-size: 18px;
    font-weight: 500;
    color: #1d3ce2;
    margin-bottom: 0;
}

.doc-text p {
    font-size: 13px;
    color: #f9313b;
    margin-bottom: 0;
}

.doc-text span {
    font-weight: bold;
    font-size: 13px;
}

.opd {
    color: #1d3ce2;
    font-weight: 600;
    font-size: 18px;
}

.opd-table {
    color: #000;
    font-size: 13px;
}

/* Contact Us */
.location-classic-content {
    border-radius: 15px;
}

.location-classic-content .inner-box {
    align-items: center;
    background: #fff;
    border-radius: 25px;
    display: flex;
    overflow: hidden;
    padding: 10px;
    position: relative;
}

.default-sidebar {
    margin-right: 10px;
    position: relative;
}

.default-sidebar .sidebar-widget {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 70px #0000001a;
    margin-bottom: 40px;
    position: relative;
}

.default-sidebar .location-title:not(:last-child) {
    border-bottom: 1px solid #0000001a;
}

.default-sidebar .location-title {
    padding: 15px 30px;
    position: relative;
}

.default-sidebar .location-title h3 {
    color: #f9313b;
    font-size: 23px;
    font-weight: 600;
}

.default-sidebar .location-title p {
    color: #676767;
}

.default-sidebar .location-title ul {
    padding-left: 0;
}

.default-sidebar .location-title ul li {
    color: #676767;
    list-style-type: none;
}

/* DNB Course modal */
.course_title {
    color: #f9313b;
    font-size: 35px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.modal-dialog-scrollable .modal-body {
    overflow: auto;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
    display: none;
    /* Hide the scrollbar */
}

.course-box .nav-list .nav-link {
    padding: 10px 56.9px !important;
}

.facility-box .sidebar {
    border-radius: 20px;
    box-shadow: 0 2px 70px #0000001a;
    margin-bottom: 40px;
}

.facility-box .sidebar .academic-title {
    padding: 15px;
}

.facility-box .sidebar .academic-title h3 {
    border-bottom: 1px solid #0000001a;
    color: #f9313b;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 0;
    text-align: center;
}

/* -------------------------------------- Aditya Ashwini Hospital -------------------------------------------- */
.facility-available {
    position: relative;
    z-index: 1;
}

.facility-widget {
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 20px;
    padding: 10px;
    background: #fff;
    text-align: center;
    transition: all .5s ease;
}

.facility-widget:hover {
    background: #f9313b;
    transform: scale(1.05);
    color: #fff;
}

.facility-widget p {
    margin: 0;
    font-size: 14px;
}

.location-section {
    background: #f4f3f8;
}

@media (max-width: 768px) {
    .chooseus-block-one .inner-box h3 {
        font-size: 20px;
        line-height: 20px;
    }

    .course_title {
        font-size: 25px;
    }

    .course_modal {
        padding: 1px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .chooseus-block-one .inner-box h3 {
        font-size: 20px;
        line-height: 20px;
    }
}