/* About Banner */
.page-title {
    position: relative;
    padding: 165px 0;
}

.page-title .bg-layer {
    position: absolute;
    /* top: 95px; */
    top: 83px;
    width: 100%;
    height: 100%;
}

.page-title .bg-layer::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 75%;
    content: "";
    background: #0e1136;
    opacity: .6;
}

.page-title .bg-layer img {
    width: 100%;
    height: 75%;
}

.page-title .bread-crumb {
    margin-top: 80px;
}

.page-title .bread-crumb li {
    position: relative;
    display: inline-block;
    font-size: 35px;
    line-height: 32px;
    color: #fff;
    font-weight: 500;
    padding-right: 21px;
    margin-right: 3px;
}

.page-title .bread-crumb li:before {
    position: absolute;
    content: "\003E";
    right: 0;
    font-weight: 600;
    font-size: 25px;
}

.page-title .bread-crumb li a {
    color: #f9313b;
    text-decoration: none;
}

.page-title .bread-crumb li a:hover {
    text-decoration: underline;
}

.page-title .bread-crumb li:last-child {
    padding: 0 !important;
    margin: 0 !important;
}

.page-title .bread-crumb li:last-child::before {
    content: none;
}

/* About Message */
.about-style-two {
    border: none;
}

.about-style-two .about_message {
    text-align: justify;
}

/* Founder's Message */
.about-style-three {
    position: relative;
    background-color: #f4f3f8;
    padding: 40px 0;
    border: none;
}

.about-style-three .pattern-layer1 {
    position: absolute;
    top: 0;
    right: 0;
}

.image_block_three .image-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_block_three .image-box .image-shape .shape-1 {
    position: absolute;
    left: 100px;
    top: -15px;
    transform: rotate(-25deg);
}

.image_block_three .image-box .image-shape .shape-2 {
    position: absolute;
    right: 80px;
    bottom: -20px;
    transform: rotate(-40deg);
}

.image_block_three .image-box .image-1 {
    border: 1px solid rgba(0, 0, 0, .1);
    filter: drop-shadow(0 2px 70px rgba(0, 0, 0, .1));
    border-radius: 20px;
}

.image_block_three .image-box .image-1 img {
    width: 100%;
    border-radius: 20px;
}

.content_block_one .experience-box {
    position: relative;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 10px;
    padding: 20px 20px 20px 110px;
}

.content_block_one .experience-box .icon-box {
    position: absolute;
    left: 20px;
    width: 70px;
    height: 70px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    background: #1d3ce2;
}

.content_block_one .experience-box h2 {
    font-size: 22px;
    color: #1d3ce2;
    margin-bottom: 0;
}

.content_block_one .experience-box .designation {
    display: block;
    font-size: 16px;
    color: #f9313b;
}

.about-style-four {
    position: relative;
    background-color: #f4f3f8;
    padding: 40px 0;
    border: none;
}

.about-style-four .pattern-layer2 {
    position: absolute;
    bottom: 0;
    left: 0;
}

.about-style-four .pattern-layer2 img {
    width: 100%;
}

.image_block_three .image-box .image-shape .shape-3 {
    position: absolute;
    right: 98px;
    top: -25px;
    transform: rotate(15deg);
}

.image_block_three .image-box .image-shape .shape-4 {
    position: absolute;
    right: 405px;
    bottom: -15px;
}

.image_block_three .image-box .image-2 {
    border: 1px solid rgba(0, 0, 0, .1);
    filter: drop-shadow(0 2px 70px rgba(0, 0, 0, .1));
    border-radius: 20px;
}

.image_block_three .image-box .image-2 img {
    width: 100%;
    border-radius: 20px;
}

/* Core Values */
.core-img{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
}
/* Mission & Vision */
.last-section {
    position: relative;
}

.last-block-one .last-table {
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
}

.last-block-one .last-table .shape {
    position: absolute;
    left: 220px;
    bottom: 0px;
}

@media screen and (max-width: 767px) {
    .page-title {
        padding: 80px 0;
    }

    .content_block_one .content-box .experience-box h3 {
        font-size: 19px;
    }
}