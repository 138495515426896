@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.slick-slide > div {
    margin: 0 10px; 
}


#root {
    font-family: "Poppins", sans-serif;
}

/* ----------------------------------------------- Homepage -------------------------------------------- */
.banner-style-two {
    position: relative;
}

.banner-style-two .slider-item {
    height: 63vh !important;
}

.banner-style-two .slidervideo::before {
    height: 99% !important;
}

.banner-style-two .slider-item::before {
    position: absolute;
    content: "";
    background: #ebf5ff;
    width: 100%;
    height: 100%;
    opacity: .6;
    z-index: 1;
}

.banner-style-two .slider_video {
    height: 100%;
    margin-top: 83px;
    background-position: center;
    background-size: cover;
}

.banner-style-two .slider_img {
    height: 100%;
    margin-top: 95px;
    background-position: center;
    background-size: cover;
    animation-name: zoomInOut;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }

    to {
        transform: scale(1.25);
    }
}

.banner-style-two .slider-item .content-box {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
    z-index: 2;
    width: 70%;
}

.banner-style-two .slider-item .content-box h1 {
    font-size: 60px;
    line-height: 1;
    color: #343a40;
    font-weight: 700;
    opacity: 1;
    transform: translateY(-10px);
    transition: all 1000s ease;
}

.banner-style-two .slider-item .content-box p {
    color: #1D3CE2;
    opacity: 1;
    font-size: 30px;
    transform: translateY(0px);
    transition: transform 1000s ease;
}

/* Our Locations */
.location-block-one {
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 200ms;
    animation-name: fadeInUp;
}

.location-block-one .inner-box {
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 20px;
    padding: 10px;
    background: #fff;
    margin-bottom: 30px;
}

.card-img-top {
    border-radius: 15px;
}

.location h5 {
    display: block;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
    color: #f9313b;
}

.location p {
    text-align: center;
}

.show_location {
    text-align: center;
    font-size: 20px;
    background-color: #fff;
    border: 0;
    transition: all .5s ease;
}

.show_location:hover {
    transform: scale(1.05);
}

.show_location .small {
    color: #6c757d;
    font-weight: 500;
    text-decoration: none;
    background: #fff;
    border: none;
}

.show_location .small:hover {
    color: #212529;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.popup.show {
    opacity: 1;
    pointer-events: all;
}

.popup-inner {
    background-color: #fff;
    padding: 50px 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
}

.popup.show .popup-inner {
    transform: scale(1);
}

.popup-inner h4 {
    margin-bottom: 20px;
}

.popup-inner .btn {
    margin: 5px;
    padding: 10px 25px;
}

/* ------------------------------------------- Message section ----------------------------------------- */
.about-section {
    background-color: #f4f3f8;
    position: relative;
    padding: 50px;
}

.about-section .pattern-layer .pattern-1 {
    position: absolute;
    left: 100px;
    top: 100px;
}

.about-section .pattern-layer .pattern-2 {
    position: absolute;
    right: 200px;
    top: 360px;
}

.about-section .pattern-layer .pattern-3 {
    position: absolute;
    left: 58%;
    top: 25px;
}

.about-section .pattern-layer .pattern-4 {
    position: absolute;
    top: 0;
    right: 0;
}

.rotate-me {
    animation-name: rotateme;
    animation-duration: 24s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotateme {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.image_block_one .image-box {
    position: relative;
    display: block;
}

.image_block_one .image-box .shape {
    position: absolute;
    left: -70px;
    bottom: 210px;
}

.image_block_one .image-box .image {
    position: relative;
    display: block;
    filter: drop-shadow(0 2px 70px rgba(0, 0, 0, .1));
}

.image_block_one .image-box .image img {
    width: 100%;
    border-radius: 300px 0 300px 300px;
}

.content_block_one .content-box {
    position: relative;
    display: block;
}

.sec-title {
    position: relative;
    display: block;
    margin-bottom: 15px;
    text-align: center;
    color: #f9313b;
    z-index: 1;
}

.sec-title .sub-title {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: .8px;
    margin-bottom: 15px;
}

.sec-title h1 {
    position: relative;
    display: block;
    font-size: 35px;
    font-weight: 600;
    margin: 0;
}

.text-box {
    margin-bottom: 3rem;
    text-align: justify;
}

.theme-btn.btn-one {
    background: #1d3ce2;
}

.theme-btn.btn-one:hover,
.theme-btn.btn-two:hover {
    background: #0e1136;
}

.theme-btn {
    position: relative;
    display: inline-block;
    font-weight: 600;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border-radius: 40px;
    cursor: pointer;
    border: none;
    transition: all .5s ease;
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
}

.theme-btn:hover::before {
    border-radius: 0;
    transform: scale(1) rotate(-180deg);
}

.theme-btn::after,
.theme-btn::before {
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #0e1136;
    transition: all .5s cubic-bezier(.68, -.55, .265, 1.55);
    transform-origin: center;
    transform: scale(0) rotate(0);
}

.theme-btn span {
    position: relative;
    display: inline-block;
    padding: 18px 30px;
}

.theme-btn span:hover {
    color: #fff;
}

.theme-btn span::before {
    position: absolute;
    content: "";
    background: #1d3ce2;
    width: 30px;
    height: 30px;
    left: 0px;
    top: -8px;
    border-radius: 50%;
    transform: scale(0);
    transition: all .5s ease;
}

.theme-btn span::after {
    position: absolute;
    content: "";
    background: #1d3ce2;
    width: 30px;
    height: 30px;
    right: 0px;
    bottom: -8px;
    border-radius: 50%;
    transform: scale(0);
    transition: all .5s ease;
}

.theme-btn:hover span::before {
    transform: scale(1);
}

.theme-btn:hover span::after {
    transform: scale(1);
}

/* ------------------------------------------- Our Departments ----------------------------------------- */
.service-section {
    position: relative;
    background-color: #f8f9fa;
}

.fadeInUp {
    animation-name: fadeInUp;
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 0ms;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.service-block-one .inner-box {
    margin-bottom: 30px;
    background: #fff;
    border-radius: 20px;
    transition: all .5s ease;
}

.service-block-one .inner-box:hover {
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
}

.service-block-one .inner-box .image-box {
    position: relative;
}

.service-block-one .inner-box .image-box .image {
    overflow: hidden;
    border-radius: 20px 20px 0 0;
}

.service-block-one .inner-box .image-box .image img {
    width: 100%;
    transition: all .5s ease;
}

.service-block-one .inner-box:hover .image-box .image img {
    transform: scale(1.05);
}

.service-block-one .inner-box .image-box .icon-box {
    background: #1d3ce2;
    position: absolute;
    left: -10px;
    bottom: 40px;
    width: 70px;
    height: 70px;
    border-radius: 0 10px 10px 0;
    transition: all .5s ease;
}

.service-block-one .inner-box:hover .image-box .icon-box {
    bottom: 85px;
}

.service-block-one .inner-box .image-box .icon-box::before {
    position: absolute;
    content: "";
    background: #0109af;
    width: 10px;
    height: 12px;
    left: 0;
    bottom: -12px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 0);
}

.service-block-one .inner-box .image-box .icon-box img {
    width: 50px;
    position: absolute;
    top: 10px;
    left: 8px;
}

.service-block-one .inner-box .lower-content {
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 0 0 20px 20px;
    padding: 5px 1px;
    transition: all .5s ease;
    text-align: center;
}

.service-block-one .inner-box .lower-content span {
    font-size: 14px;
    line-height: 36px;
    font-weight: 600;
}

.service-block-one .inner-box .lower-content span a {
    color: #0e1136;
    text-decoration: none;
}

.service-block-one .inner-box .lower-content span a:hover {
    color: #1d3ce2;
}

/* ------------------------------------------- Health Packages ----------------------------------------- */
.healthpackage-section {
    background: #f4f3f8;
}

.healthpackage-block-one {
    visibility: visible;
    animation-duration: 1500ms;
    animation-delay: 200ms;
    animation-name: fadeInUp;
}

.healthpackage-block-one .inner-box {
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 20px;
    padding: 10px;
    background: #fff;
    margin-bottom: 30px;
    position: relative;
}

.healthpackage-title {
    padding: 0.5rem;
}

.healthpackage-title h5 {
    display: block;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
    color: #f9313b;
}

.show_package {
    text-align: center;
    font-size: 20px;
    background-color: #fff;
    border: 0;
    transition: all .5s ease;
}

.show_package .small {
    color: #6c757d;
    font-weight: 500;
    text-decoration: none;
    background: #fff;
    border: none;
}

.healthpackagetitle {
    color: #f9313b;
    font-size: 35px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.healthpackageprice {
    color: #1d3ce2;
    font-weight: 600;
    font-size: 40px;
}

.healthpackageprice span {
    color: #f9313b;
}

.footer_lists {
    background: #f4f3f8;
    padding: 0;
}

.instructionstitle {
    color: #f9313b;
    font-size: 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.footer_lists ul li {
    font-size: 12px;
    color: #1d3ce2;
    font-weight: 600;
}

/* ------------------------------------------- Booking section ----------------------------------------- */
.booking-section {
    position: relative;
    overflow: hidden;
    padding: 80px 20px;
}

.booking-section .bg-layer::before {
    position: absolute;
    content: "";
    background: #0e1136;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .6;
}

.parallax-bg {
    position: absolute;
    left: 0;
    top: 0%;
    height: 100%;
}

.booking-section .image-layer {
    position: absolute;
    right: 60px;
    top: 3px;
}

.booking-section .inner-box {
    position: relative;
}

.booking-section .inner-box .shape {
    position: absolute;
    right: 50%;
    bottom: 0;
    max-width: 50px;
}

.booking-section .inner-box h1 {
    font-size: 55px;
    line-height: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: left;
}

/* ------------------------------------------- Partners Section ----------------------------------------- */
.partners-section {
    position: relative;
    background: #f4f3f8;
}

.partners-img {
    padding: 0 5px;
    border-radius: 25px;
    width: 500px;
}

/* ------------------------------------------- Stats Section ----------------------------------------- */
.stats-container{
    background-color: #f8f9fa;
    padding: 12px;
}
.stats-container >h2{
    color:#f9313b;
    font-weight: 600;
}
.stats-sec>h5{
    color:#f9313b;
    font-weight: 600;
    font-size: 2.25rem;
}
.stats-sec>p{
    color: #2a2a2e;
    font-weight: 600;
    font-size: 18px;
}
/* .stats{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.stats-sec{
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
} */
/* ------------------------------------------- Workshop Modal ----------------------------------------- */
/* .workshop_title {
    color: #f9313b;
    font-size: 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.close_btn {
    box-shadow: 0 2px 70px rgba(0, 0, 0, .1);
    border-radius: 40px;
    padding: 18px 30px;
    font-weight: 600;
}

.modal-footer {
    border-top: none;
} */

/* Media Queries Responsive adjustments */
@media (max-width: 768px) {
    .sec-title h2 {
        font-size: 30px;
    }

    .healthpackagetitle {
        font-size: 17px;
    }

    .healthpackageprice {
        font-size: 30px;
    }

    .footer_lists ul li {
        font-size: 10px;
    }

    .booking-section .inner-box h2 {
        font-size: 35px;
        line-height: 55px;
        margin-bottom: 28px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .banner-style-two .slider-item {
        height: 35vh !important;
    }
}

@media (max-width: 992px) {
    .banner-style-two .slider-item {
        height: 40vh !important;
    }

    .banner-style-two .slider-item .content-box {
        width: 100%;
        top: 70%;
    }

    .banner-style-two .slider-item .content-box h1 {
        font-size: 35px;
    }

    .banner-style-two .slider-item .content-box p {
        font-size: 20px;
    }
}