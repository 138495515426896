.header-top {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    /* padding-top: 1.5rem; */
    /* padding-bottom: 1.5rem; */
    padding-bottom: 1rem;
    background-color: #fff;
}

.nav-item {
    font-size: 20px;
    padding: 5px;
}

.nav-item a {
    color: #000 !important;
    font-weight: 600;
}

.gradeLogos {
    width: 35px;
}

/* Base dropdown menu styling */
.dropdown-menu {
    display: block;
    background-color: #f4f3f8;
    margin-left: -15rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

/* Show dropdown on hover */
.nav-item.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    /* Remove display property to enable transitions */
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
    background-color: #ffffff;
}

/* Responsive adjustments */
@media (min-width: 768px) and (max-width: 1024px) {
    .navbar-brand {
        margin-right: 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0;
    }
}

@media (max-width: 992px) {
    .nav-item.dropdown:hover .dropdown-menu {
        display: none;
    }
    .nav-item {
        font-size: 18px;
        margin-right: 0;
    }

    .navbar-brand img {
        max-width: 200px;
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: space-evenly !important;
    }
    .dropdown-menu{
        display: none;
    }
}

@media (min-width: 993px) and (max-width: 1399px) {
    
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    margin-left: -20rem;
}
    .nav-item {
        font-size: 12px;
        padding: 0;
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: space-evenly !important;
    }
}