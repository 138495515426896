.main-footer {
    position: relative;
    background: #0e1136;
}

.main-footer .pattern-layer .pattern-1 {
    position: absolute;
    left: 0;
    opacity: .5;
    bottom: 0;
}

.main-footer .pattern-layer .pattern-1 img {
    width: 100%;
}

.main-footer .pattern-layer .pattern-2 {
    position: absolute;
    left: 70px;
    top: 0;
}

.main-footer .pattern-layer .pattern-3 {
    position: absolute;
    top: 130px;
    right: 90px;
}

.main-footer .pattern-layer .pattern-4 {
    position: absolute;
    left: -320px;
    bottom: -5px;
    background: #181b3e;
    width: 510px;
    height: 430px;
    border-radius: 50%;
    animation: zoom-fade 8s linear infinite;
}

@keyframes zoom-fade {
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(.9);
    }
}

.main-footer .widget-section {
    position: relative;
    border-bottom: 1px solid hsla(0, 0%, 100%, .2);
    padding: 40px 0;
}

.main-footer a {
    color: #fff;
}

.social-links {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0;
}

.widget-section .logo-widget .social-links li {
    display: inline-block;
    margin-right: 20px;
}

.footer_icon {
    font-size: 40px;
}

.ml {
    margin-left: 70px;
}

.widget-section .footer-title h3 {
    font-size: 26px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
}

.links-list {
    list-style-type: none;
    padding: 0;
}

.widget-section .links-widget li a {
    font-weight: 500;
    line-height: 40px;
    text-decoration: none;
}

.widget-section a:hover {
    color: #f9313b;
}

.info-list {
    padding: 0;
}

.widget-section .contact-widget .info-list li {
    position: relative;
    list-style-type: none;
    margin-bottom: 10px;
    color: #fff;
}

.widget-section .contact-widget .info-list li .contact_icon {
    position: absolute;
    top: 3px;
    font-size: 20px;
}

.widget-section .contact-widget .info-list li .contact_text {
    margin-left: 25px;
    text-decoration: none;
}

.footer-bottom {
    position: relative;
    width: 100%;
    padding: 20px 0;
}

.footer-bottom .bottom-inner {
    position: relative;
}

.main-footer a,
.main-footer p {
    color: #fff;
}

/* Media Queries Responsive adjustments */
@media (max-width: 768px) {
    .ml {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .widget-section img {
        width: 230px;
    }

    .widget-section .logo-widget .social-links li {
        margin-right: 10px;
    }

    .ml {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {}