.contact-section {
    position: relative;
    margin-top: -34px;
}

.contact-section .bg-layer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.contact-section .form-inner {
    padding: 25px;
}

.contact-section .sec-title .job {
    margin-bottom: 10px;
    text-align: left;
}

.contact-section .default-form .form-group input,
.contact-section .default-form .form-group select,
.contact-section .default-form .form-group textarea {
    position: relative;
    height: 70px;
    border-radius: 10px;
    padding: 10px 30px;
    font-size: 18px;
    color: #676767;
    transition: all .5s ease;
}

.contact-section .form-inner .resume {
    color: rgb(103, 103, 103);
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
}

.contact-section .form-inner .resume span {
    color: #f9313b;
    font-size: 18px;
}