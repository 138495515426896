
/* General styles */
.healthpackage-details{
    /* margin-top: 7rem; */
    margin-top: 86px;
    /* margin-bottom: 1rem; */
    background: #f4f3f8;
}

.table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f4f4f4;
}

.table img {
    max-width: 100px;
    height: auto;
}

/* Accordion styles for mobile view */
.accordion {
    margin-top: 20px;
}

.accordion-button {
    background-color: #f8f9fa;
    font-weight: bold;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-body img {
    max-width: 50px;
    height: auto;
    display: block;
    margin: 10px 0;
}

/* Instructions Section Styles */
.instructions {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.instructions h6 {
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 10px;
}

.instructions ul {
    list-style: disc;
    padding-left: 20px;
    color: blue;
}

.instructions ul li {
    margin-bottom: 8px;
    line-height: 1.5;
}

/* Mobile View */
@media (max-width: 768px) {
    .h-pack{
        display: none;
    }
    .pack-title {
        color: red;
        text-align: center;
        font-weight: 600;
    }
    .pack-price {
        color: red;
    }
    .instructions ul {
        list-style-type: none;
        padding-left: 0;
    }

    .instructions ul li {
        padding-left: 15px;
        position: relative;
    }

    .instructions ul li::before {
        content: '•';
        color: rgb(45, 13, 247);
        position: absolute;
        left: 0;
        top: 0;
    }
}

/* Desktop View */
@media (min-width: 768px) {
    
    .pack-title {
        color: red;
        text-align: center;
        font-weight: 600;
        font-size: xx-large;
    }
    .pack-price {
        color: red;
        font-weight: 500;
    }
}
