.appointment-section {
    position: relative;
    margin-top: -34px;
}

.appointment-section .bg-layer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.appointment-section .form-inner {
    padding: 25px;
}

.appointment-section .sec-title .job {
    margin-bottom: 10px;
    text-align: left;
}

.appointment-section .default-form .form-group input {
    height: 70px;
    border-radius: 10px;
    padding: 10px 30px;
    font-size: 18px;
    color: #676767;
    transition: all .5s ease;
}

.appointment-section .form-inner .resume {
    color: rgb(103, 103, 103);
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
}

.appointment-section .form-inner .resume span {
    color: #f9313b;
    font-size: 18px;
}
.application-section {
    position: relative;
    padding: 60px 0;
}

.application-section .bg-layer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.application-section .container {
    position: relative;
    z-index: 1;
}

.job-listings .card {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.job-listings .card-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.job-listings .card-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}
.job-title-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.custom-text{
    color: #ff0000;
}
.location-heading{
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 2px;
}
.button-group {
    display: flex;
    gap: 10px; /* Space between buttons */
    margin-bottom: 10px; 
}

.button-group .btn {
    flex: 1; /* Ensure buttons are the same width */
    text-align: center; /* Center the text inside buttons */
}

.button-group .btn-primary {
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff; /* Primary button color */
    border-color: #007bff;
}

.button-group .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}

.button-group .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.button-group .btn-success {
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #0056b3;
    background-color: white;
    border-color: #0056b3;
    text-align: center; 
}

.button-group .btn-success:hover {
    /* background-color: #0056b3;  */
    border-color: #0056b3;
    color: #4777fe;
    background: rgba(239, 237, 255, .4);
}


.details-button {
    background: none;
    border: none;
    color: #007bff; /* Adjust the color to match your theme */
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline;
}

.details-button:hover {
    color: #0056b3; /* Adjust the hover color if needed */
}

/* Modal Styles */
.job-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.job-modal-content {
    background-color: #fefefe;
    margin: 10%;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
@media (max-width:768px){
    .job{
        margin-top: 3rem;
    }
    .job-modal-content{
        margin-top: 7rem;
    }
}
