.blog-page {
    padding: 20px;
    margin-top: 4.5rem;
    font-family: sans-serif;
    background-image: url(../../../assets/flowers-bg.png);
  }
  
  .blog-title{
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(90deg, #034ea1 0, #00b7ac 100%);
    margin-bottom: 20px;
  }
 .blog-sub-heading{  
    margin-top: 1rem;
    text-decoration: none;
    display: inline-block; 
    border-radius: 4px;
    /* padding: 10px; */
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    /* background: linear-gradient(274deg, #034ea1 0, #b700b0 100%); */
    /* margin-bottom: 20px; */
}
  
  .blog-image {
    width: 100%;
    height: auto;
  }
  
  .blog-section {
    margin-bottom: 30px;
  }
  
  .blog-section h2 {
    font-size: 1.75rem;
    color: #007bff;
  }
  /* .blog-section p,
  .blog-section ul {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #495057;
  } */
  
  /* ul {
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  } */
  .list-item-ul {
    list-style: none;
    color: #4d4747;
}
.list-item-ul li::before {
    content: "■";
    display: inline-block;
    transform: rotate(45deg);
    margin-right: 10px;
}

.case-study-section {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 20px;
    /* margin-bottom: 30px; */
  }
  
  /* .case-study-image {
    max-width: 250px;
    max-height: 236px;
    flex-shrink: 0;
  } */
  
  .case-study-text {
    flex-grow: 1;
    text-align: justify;
    min-width: 300px;
  }
.mech-action{
    color:#4d4747;
    padding-left: 30px;
}
.blog-page{
    font-size: 20px;
}
.acknowledgment-section{
    display: flex;
    gap: 15px;
}
.acknowledgment-section img{
    max-width: 200px;
}
.acknowledgment-section p{
    margin-left: 10px;
    text-align: justify;
}

.doc-link p{
  text-align: center;
  margin-left: 0;
  font-size: 15px;
  background-color: #0d6efd;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-radius: 0 0 5px 5px;
}
@media (max-width: 992px){
    .case-study-section {
        flex-wrap: wrap;
    }
    .case-study-section img{
        margin: auto;
    }
    .acknowledgment-section {
        flex-direction: column;
    }
    .acknowledgment-section p{
        margin-left: 0px;
    }
    .doc-link{
        display: contents;
    }
}