.gallery-box {
    flex-wrap: wrap;
    gap: 10px;
}

.gallery-box button {
    flex: 1 1 100%;
    max-width: 20%;
    margin-bottom: 10px;
    font-size: 16px;
}

.gallery-tabs {
    margin-top: 8rem;
}

.gallery-items {
    position: relative;
    overflow: hidden;
    border: none;
    margin: 10px;
    padding: 0;
}
.gallery-item {
    position: relative;
    overflow: hidden;
    border: none;
    margin: 10px;
    padding: 0;
}

.gallery-items img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}
.gallery-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

/* .gallery-items:hover img {
    transform: scale(1.1);
} */

.hover-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: white;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-align: center;
}

/* .gallery-items:hover .hover-text {
    opacity: 1;
    width: 100%;
} */
.gallery-text{
    margin-top: 5px;
    text-align: center;
    background-color: #2d72b8;
    color: white;
    padding: 10px;
    /* border: 2px solid #8c00f9; */
    border-radius: 5px;
}

@media (max-width: 768px) {
    .gallery-box button {
        flex: 1 1 auto;
        max-width: 200px;
        margin-bottom: 0px;
        font-size: 16px;
    }
}